<div class="max-w-6xl mx-auto p-4">
  <div class="text-3xl py-8 text-center">Buono da € {{faceValue}}</div>
  <div class="flex flex-col justify-center gap-8">
    <div class="text-2xl text-center">
      Scrivere il numero di telefono del cliente
    </div>
    <div class="mx-auto">
      <fa-icon icon="mobile-screen" size="xl"></fa-icon>
      <app-input
      type="text"
      [value]="value"
      placeholder="Numero di telefono"
      aria-label="default input example"
      (onChange)="handleChange($event)"
      />
    </div>
    <!--<div class="text-2xl text-center">Note (facoltativo)</div>
    <div class="mx-auto">
      <app-input
      type="text"
      placeholder="Inserire nota"
      aria-label="default input example"
      />
    </div>-->
    <div class="mx-auto">
      <app-button type="button" (click)="hadleUserRegistration()">Invia</app-button>
    </div>
  </div>
</div>
