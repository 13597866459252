import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
import { faBagShopping, faCar, faCreditCard, faFile, faFlag, faMobile, faMobileScreen, faMoneyBills, faRotate, faShop, faStar, faStore, faTag, faTicket, faUser, faUtensils, faWallet, faFilm, faBook, faShirt } from '@fortawesome/free-solid-svg-icons';
export const iconsConfig = [
  faUtensils,
  faTicket,
  faFilm,
  faWallet,
  faCar,
  faFile,
  faMobile,
  faMobileScreen,
  faBagShopping,
  faFlag,
  faStore,
  faTag,
  faRotate,
  faStar,
  faShop,
  faUser,
  faMoneyBills,
  faCreditCard,
  faFaceSmile,
  faBook,
  faShirt
]
