import { Component } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-base',
  template: '',
})
export class BaseComponent {


  constructor(
    protected router: Router
  ) { }

  protected navigateTo = (path: string, params?: any) => {
    this.router.navigate([path], { queryParams: {...params} });
  }

}
