import {Router} from "@angular/router";
import {HTTP_INTERCEPTORS, HttpInterceptor, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {Injectable} from "@angular/core";
import { StorageService } from "../services/storage.service";
import { STORAGE } from "../models/enum/general";
import { Settings } from "../models/interfaces/Settings";
import { ErrorHandlerService } from "../services/error-handler.service";



const TOKEN_HEADER_KEY = 'Authorization';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  storage = STORAGE;
  constructor( private localStorageService: StorageService,
              private errorHandler: ErrorHandlerService,
              private router: Router) { }

  private handleAuthError( err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      this.localStorageService.removeAllFromStorage();
      this.errorHandler.errorHandler(err);
      this.router.navigateByUrl('signin');
      return throwError(() => err);
    }
    if (err.status === 403) {
      this.localStorageService.removeAllFromStorage();
      this.router.navigateByUrl('signin');
      this.errorHandler.errorHandler(err);
      return throwError(() => err);
    }
    this.errorHandler.errorHandler(err);
    return throwError(() => err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq: HttpRequest<any> = req;
    let setting: Settings = this.localStorageService.getStorage(this.storage.SETTINGS);
    if (setting && setting.accessToken) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + setting.accessToken) });
    }
    return next.handle(authReq).pipe(catchError(x => this.handleAuthError( x)));
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

}

export const authInterceptorProviders: any = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];