import { ChangeDetectorRef, Component, DestroyRef, inject } from '@angular/core';
import { AdminService } from './services/admin.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IChallengeTokenRes } from 'src/app/models/response/IChallengeTokenRes';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  identify: string = '';
  context: string = ''
  destroyRef = inject(DestroyRef);
  webappUrl: string = '';
  showCt = false;

  constructor(
    private adminService: AdminService, private changeDetectorRef: ChangeDetectorRef, private route: ActivatedRoute 
  ) {
    this.route.queryParams.subscribe(params => {
      this.context = params['context'] ? params['context'] === 'client' ? 'mobile-account' : params['context'] === 'seller' ? 'seller-account' : params['context'] === 'merchant' ? 'merchant-account': 'mobile-account' : 'mobile-account';
      this.identify = params['identifier'] ? params['identifier'] : '';
      this.generateCt();
  });
  }

  public generateCt() {
    if(this.identify && this.context) {
        this.adminService.generateCt( this.identify, this.context).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
          {
            next: this.handleissuerRes.bind(this),
            error: this.handleIssuerError.bind(this),
          }
        )
    }
  }

  handleissuerRes = (res: IChallengeTokenRes) => {
    if(res && res.challengeToken) {
      this.webappUrl = (this.context == 'mobile-account' ?  environment.webappUrl : this.context == 'seller-account' ? environment.sellerUrl : this.context == 'merchant-account' ? environment.merchantUrl : '') + '/ct/' + res.challengeToken;
      this.changeDetectorRef.detectChanges(); 
    }
   
  }

  handleIssuerError = (error: any) => {
    this.webappUrl = 'Si è verificato un errore';
    this.changeDetectorRef.detectChanges(); 
  }

  public getAlgoAddess() {
    if(this.identify && this.context) {
      this.adminService.generateCt( this.identify, this.context).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
        {
          next: this.handleissuerRes.bind(this),
          error: this.handleIssuerError.bind(this),
        }
      )
  }
  }

}

