import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { IssuerComponent } from './components/issuer/issuer.component';
import { VoucherTypeComponent } from './components/voucher-type/voucher-type.component';
import { CustomerTypeComponent } from './components/customer-type/customer-type.component';
import { UnregisteredUserComponent } from './components/unregistered-user/unregistered-user.component';
import { ConfirmUserComponent } from './components/confirm-user/confirm-user.component';
import {ZXingScannerModule} from "@zxing/ngx-scanner";
import {HttpClientModule} from "@angular/common/http";
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { PaymentResultComponent } from './components/payment-result/payment-result.component';
import { BaseComponent } from './_shared/base/base.component';
import { ReaderComponent } from './components/reader/reader.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './_shared/header/header.component';
import { ButtonComponent } from './_shared/button/button.component';
import { InputComponent } from './_shared/input/input.component';
import { iconsConfig } from 'icons.config';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { SigninComponent } from './components/signin/signin.component';
import { ChallangeTokenComponent } from './components/challange-token/challange-token.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { authInterceptorProviders } from './interceptors/auth.interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminComponent } from './components/admin/admin.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgToastModule } from 'ng-angular-popup';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IssuerComponent,
    VoucherTypeComponent,
    CustomerTypeComponent,
    UnregisteredUserComponent,
    ConfirmUserComponent,
    PaymentMethodComponent,
    PaymentResultComponent,
    BaseComponent,
    ReaderComponent,
    HeaderComponent,
    ButtonComponent,
    InputComponent,
    SigninComponent,
    ChallangeTokenComponent,
    AdminComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ZXingScannerModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    FontAwesomeModule,
    NgbAccordionModule,
    NgToastModule,
    NgxSpinnerModule.forRoot(),
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    library: FaIconLibrary
  ){
    library.addIcons(...iconsConfig)
  }
}
