
<div class="max-w-6xl mx-auto p-4">
  <div class="voucher-type-title">Pagamento effettuato</div>
  <div class="confirm-user-subtitle">Il buono è stato appena inviato</div>
  <div class="flex justify-center items-center py-8">
    <fa-icon [icon]="['far','face-smile']" size="xl" class="text-8xl text-gray-400"></fa-icon>
  </div>
  <div class="confirm-user-subtitle"><a href="{{webappUrl}}" target="_blank">{{webappUrl}}</a></div>
  <div class="flex gap-8 justify-center items-center py-8">
    <app-button [style]="'secondary'" (click)="printReceipt()">
      Stampa scontrino
    </app-button>

    <app-button (click)="navigateTo('home')">
      Torna alla home
    </app-button>
  </div>
</div>
