<div class="max-w-5xl mx-auto p-4">
  <div class="text-3xl pb-6 text-center">Selezionare il tipo di buono:</div>
  <ng-container *ngIf="voucherTypes$ | async as voucherTypes;">
    <div class="flex flex-col md:flex-row flex-wrap justify-center">  
      <div *ngFor="let voucher of voucherTypes.data " class=" md:basis-1/3">
        <div class="relative bg-gray-300 rounded-2xl p-4 m-4">
          <a class="home-sub-container text-3xl" (click)="handleVoucherTypeSelection({
              faceValue: voucher.nominalValue,
              voucherKey: voucher.voucherKey,
              description: voucher.description
            })" style="cursor: pointer">
            {{ voucher.nominalValue }} €
          </a>
          <span class="sr-only">Notifications</span>
          <div class="absolute inline-flex items-center justify-center w-8 h-8 text-s font-bold text-white bg-indigo-700 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">{{voucher.voucherAmount}}</div>
        </div>
      </div>
    </div>
    <div class="text-center pt-8" *ngIf="voucherTypes.allowCustomFaceValue">
      <div class="text-lg">Oppure</div>
      <div class="text-2xl"><strong>Inserisci una cifra</strong></div>
      <div class="voucher-type-input-container">
        <app-input type="text" placeholder="Scrivere qui il prezzo" aria-label="default input example"
          [value]="faceValue" (onChange)="setFaceValue($event)" />
      </div>
      <div *ngIf="faceValue" class="pt-4">
        <app-button (click)="handleFaceValueSelection()">Procedi</app-button>
      </div>
    </div>
  </ng-container>
  <div  *ngIf="noVoucher" class="text-2xl pb-6 text-center">Nessuna tipologia di buono presente per il merchant selezionato</div>
</div>
