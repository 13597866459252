<div class="max-w-6xl mx-auto p-4">
<div class="flex flex-col sm:grid sm:grid-cols-4 gap-8 p-8" >
  <div class="bg-gray-300 rounded-2xl p-4">
    <div class="home-sub-container text-2xl flex-col">
      <fa-icon icon="utensils" size="xl"></fa-icon>
      <span class="mt-4">Bar e ristoranti</span>
    </div>
  </div>
  <div  class="bg-gray-300 rounded-2xl p-4">
    <div class="home-sub-container text-2xl flex-col">
      <fa-icon icon="ticket" size="xl"></fa-icon>
      <span class="mt-4">Biglietti eventi</span>
    </div>
  </div>
  <div class="col-span-2 bg-gray-300 rounded-2xl p-4">
    <a class="home-sub-container text-2xl flex-col" style="cursor: pointer" [routerLink]="['/issuer']">
      <fa-icon icon="wallet" size="xl"></fa-icon>
      <span class="mt-4">Buoni Digitali</span>
    </a>
  </div>
  <div class="col-span-2 bg-gray-300 rounded-2xl p-4">
    <div class="home-sub-container text-2xl flex-col">
      <fa-icon icon="car" size="xl"></fa-icon>
      <span class="mt-4">Bollo auto</span>
    </div>
  </div>
  <div  class="bg-gray-300 rounded-2xl p-4">
    <div class="home-sub-container text-2xl flex-col">
      <fa-icon icon="file" size="xl"></fa-icon>
      <span class="mt-4">Bollettini</span>
    </div>
  </div>
  <div class="bg-gray-300 rounded-2xl p-4">
    <div class="home-sub-container text-2xl flex-col">
      <fa-icon icon="mobile-screen" size="xl"></fa-icon>
      <span class="mt-4">Ricariche Telefoniche</span>
    </div>
  </div>

</div>
