import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {IAccountRes} from "../../../models/response/IAccountRes";
import { LoadingService } from 'src/app/services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class UnregisteredUserService {

  constructor(private http: HttpClient, private loading: LoadingService) { }

  accounts(mobile: string, useMock = false): Observable<IAccountRes> {
    if(useMock) {
      let res: IAccountRes = {
        clientKey: "NXycDQKzlQ2HPyDC20O6j6",
        identifier: ''
      }
      return of<IAccountRes>(res);
    }
    return this.loading.switchLoading(this.http.post(environment.api.account, {mobile: mobile}).pipe(map((resp: IAccountRes | any) => {
        if(resp  ) {
          return resp;
        }
        return null;
      })
    ));
  }


}
