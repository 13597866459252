import { Injectable } from '@angular/core';
import { compress, decompress } from 'lz-string';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  
  /**
   *
   * @param {string} key The unique identifier under which the data will be stored in the storage.
   * @param {any} items The data to be stored.
   * @param {boolean} [storeOnLocal=true] A flag indicating whether to store the data in local storage (true) or session storage (false).
   * @param {boolean}[storeCompressed=true] A flag indicating whether to compress the data before storing it (true) or store it as is (false).
   * @memberof StorageService
   * @description This function is used to store data in the web browser's local storage or session storage. It serializes the provided data, optionally compresses it, and then stores it in either local storage or session storage based on the specified parameters.
   */
  public setStorage = (
    key: string,
    items: any,
    storeOnLocal: boolean = true,
    storeCompressed: boolean = true,
  ) => {
    let itemsToStore: string = storeCompressed
      ? compress(JSON.stringify(items))
      : JSON.stringify(items);
    storeOnLocal
      ? localStorage.setItem(key, itemsToStore)
      : sessionStorage.setItem(key, itemsToStore);
  };

 /**
  *
  * @param {stirng} key The unique identifier under which the data will be stored in the storage.
  * @param {boolean} [storeOnLocal=true] A flag indicating whether to store the data in local storage (true) or session storage (false).
  * @param {boolean} [storeCompressed=true] A flag indicating whether the stored data is compressed (true) or stored as a JSON string (false).
  * @memberof StorageService
  * @description This function retrieves stored data from either local storage or session storage in the web browser. It optionally decompresses the data and parses it back into its original format before returning it. If the specified key does not exist in the storage, the function returns null.
  */
 public getStorage = (
   key: string,
   storeOnLocal: boolean = true,
   storeCompressed: boolean = true,
 ) => {
   let itemStored: any = storeOnLocal
     ? localStorage.getItem(key)
     : sessionStorage.getItem(key);
   if(!itemStored) return null;
   return storeCompressed
     ? JSON.parse(decompress(itemStored))
     : JSON.parse(itemStored);
 };

 /**
  *
  * @param {string} key  The unique identifier of the item to be removed from the storage.
  * @param {boolean} [removeOnlocal=true] A flag indicating whether the item is stored in local storage (true) or session storage (false). The default value is true, indicating local storage.
  * @memberof StorageService
  * @description This function removes a specific item from either local storage or session storage in the web browser, based on the specified key.
  */
 public removeFromStorage = (key: string, removeOnlocal: boolean = true) => {
   removeOnlocal
     ? localStorage.removeItem(key)
     : sessionStorage.removeItem(key);
 };

 /**
  * @param {boolean} [removeOnlocal=true] A flag indicating whether to clear items from local storage (true) or session storage (false). The default value is true, indicating local storage.
  * @memberof StorageService
  * @description This function clears all items from either local storage or session storage in the web browser, based on the specified storage type.
  */
 public removeAllFromStorage = (removeOnlocal: boolean = true) => {
   removeOnlocal ? localStorage.clear() : sessionStorage.clear();
 };
}
