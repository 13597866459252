import { ErrorHandlerService } from './../../services/error-handler.service';
import { Component, DestroyRef, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/_shared/base/base.component';
import { Settings } from 'src/app/models/interfaces/Settings';
import { StorageService } from 'src/app/services/storage.service';
import { STORAGE } from 'src/app/models/enum/general';
import { Utils, receipt } from 'src/app/utils/utils';
import * as _ from 'lodash';
import { ConfirmUserService } from '../confirm-user/services/confirm-user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss']
})

export class PaymentResultComponent extends BaseComponent  {
  clientKey: string = '';
  voucherKey: string = '';
  settings: Settings = {};
  storage = STORAGE;
  webappUrl: string = '';
  destroyRef = inject(DestroyRef);
  receiptToPrint = _.cloneDeep(receipt);

  constructor(
    router: Router,
    private route: ActivatedRoute,
    private localStorageService: StorageService,
    private confirmUserService: ConfirmUserService,
    private errorHandler: ErrorHandlerService
  ) {
    super(router);
    this.settings = this.localStorageService.getStorage(this.storage.SETTINGS);
    this.webappUrl = this.settings.urlWebApp ? this.settings.urlWebApp : '';
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.clientKey = params['clientkey'];
        this.voucherKey = params['voucherKey'];
      }
    );
  }

  printReceipt = () => {
    this.settings = this.localStorageService.getStorage(this.storage.SETTINGS);
    console.log('setting: ' + JSON.stringify(this.settings.extendedVoucherData));
    const currentDate = new Date(); // Get the current date and time
    const date = Utils.formatDate(currentDate); // Generate the formatted date string
    const amount =  Utils.formatAmount(this.settings.extendedVoucherData?.amount ? this.settings.extendedVoucherData?.amount.toString() : '0,00');
    this.receiptToPrint.data[12].value = Utils.interpolationTemplate(this.receiptToPrint.data[12].value, {date: date});
    this.receiptToPrint.data[14].value = Utils.interpolationTemplate(this.receiptToPrint.data[14].value, {telephoneNumber: this.settings.extendedVoucherData?.telephoneNumberOwner ? this.settings.extendedVoucherData?.telephoneNumberOwner : ''});
    this.receiptToPrint.data[29].value = Utils.interpolationTemplate(this.receiptToPrint.data[29].value, {amount: amount});
    this.receiptToPrint.data[19].value = Utils.interpolationTemplate(this.receiptToPrint.data[19].value, {description: this.settings.extendedVoucherData?.description ? this.settings.extendedVoucherData?.description : ''});
    this.receiptToPrint.data[34].value = Utils.interpolationTemplate(this.receiptToPrint.data[34].value, {txId: this.settings.extendedVoucherData?.txId ? this.settings.extendedVoucherData?.txId : ''});
    this.confirmUserService.print(this.receiptToPrint).pipe(takeUntilDestroyed(this.destroyRef)).subscribe( {
      next: this.handleissuerRes.bind(this),
      error: this.handleIssuerError.bind(this)
    });
    }


    handleissuerRes = (res: any) => {
      console.log('printReceipt: ', res);
    }
  
    handleIssuerError = (error: any) => {
      this.errorHandler.errorHandler(error);
      console.log('printReceipt: ', error);
    }


}
