export const environment = {
    production: false,
    webappUrl: 'https://app.gift-vault.net',
    sellerUrl: 'https://app-seller.gift-vault.net',
    merchantUrl: 'https://app-merchant.gift-vault.net',
    api: {
      signin: '/api/account/login',
      issuer: '/api/issuer',
      vouchers: '/api/issuer/${issuerClientKey}/voucher',
      account: '/api/account',
      ct: '/api/ct/token',
      transfer: '/api/voucher/${voucherKey}',
      print: 'http://localhost:8100/printer/line'
    },
    loggerServiceActive: true,
    firebaseConfig : {
      apiKey: "AIzaSyBXOnb-I1VwXpHpGdQ6XmD56dvdy2yfWxU",
      authDomain: "fp8-ledger-dev.firebaseapp.com",
      projectId: "fp8-ledger-dev",
      storageBucket: "fp8-ledger-dev.firebasestorage.app",
      messagingSenderId: "207320605821",
      appId: "1:207320605821:web:9ba8464ed7f26a7c6e7d14",
      measurementId: "G-WME0TT2L8H"
      }
  };
  