import {Component, EventEmitter, Output} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/_shared/base/base.component';

@Component({
  selector: 'app-customer-type',
  templateUrl: './customer-type.component.html',
  styleUrls: ['./customer-type.component.scss']
})
export class CustomerTypeComponent extends BaseComponent  {

  constructor(
  router: Router,
  private route: ActivatedRoute
  ) {
    super(router)
  }

  routeParams = this.route.snapshot.queryParams;

  handleCustomerTypeSelection(url:string){
    this.navigateTo(url, this.routeParams)
  }
}
