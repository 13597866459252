import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { STORAGE } from 'src/app/models/enum/general';
import { Settings } from 'src/app/models/interfaces/Settings';
import { StorageService } from 'src/app/services/storage.service';
import { SigninService } from './service/signin.service';
import { LoginReq, LoginRes } from 'src/app/models/interfaces/Login';
import { EnumAccountContextType } from 'src/app/models/enum/context';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent {
  telephone: string = '';
  setting: Settings = {};
  destroyRef = inject(DestroyRef);
  storage = STORAGE;

  constructor(private readonly router: Router,
    private readonly signinService: SigninService,
    private localStorageService: StorageService) {

}

  login = () => {
    if (this.telephone) {
      this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
      if(this.setting) {
   
        const login: LoginReq = {
          identifier: this.telephone,
          challengeToken: this.setting?.challengeToken ? this.setting?.challengeToken : '',
          context: EnumAccountContextType.SELLER
        }
        this.signinService.login(login).pipe(takeUntilDestroyed(this.destroyRef)).subscribe( {
          next: this.handleissuerRes.bind(this),
          error: this.handleIssuerError.bind(this)
        });
      } else {
       
        // this.router.navigateByUrl('home');
      }
    }
  }

  handleissuerRes = (loginRes: LoginRes) => {
    console.log(JSON.stringify(loginRes));
    if (loginRes) {
    this.setting.accessToken = loginRes.accessToken;
    this.setting.refreshToken = loginRes.refreshToken;
    this.setting.clientKey = loginRes.clientKey;
    this.localStorageService.setStorage(this.storage.SETTINGS, this.setting);

    this.router.navigateByUrl('home');
    }
  }

  handleIssuerError = (error: any) => {
     // TO DO
  }

}
