<div class="max-w-6xl mx-auto p-4">
  <div class="text-3xl px-8 text-center">Tipologia di cliente</div>
  <div class="grid sm:grid-cols-2 gap-8 p-8">
    <div class="bg-gray-300 rounded-2xl p-4">
      <a class="customer-type-sub-container text-2xl flex-col" style="cursor: pointer" (click)="handleCustomerTypeSelection('/qr-reader')">
        <fa-icon icon="user" size="xl"></fa-icon>
        <span class="mt-4"> Utente registrato</span>
      </a>
    </div>
    <div class="bg-gray-300 rounded-2xl p-4">
      <a class="customer-type-sub-container text-2xl flex-col" style="cursor: pointer; float: left" (click)="handleCustomerTypeSelection('/unregistered-user')">
        <fa-icon icon="mobile-screen" size="xl"></fa-icon>
        <span class="mt-4"> Utente non registrato </span>
      </a>
    </div>
  </div>
</div>
