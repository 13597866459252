import { Injectable, Signal, effect, inject, signal } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, delay, finalize, of, switchMap, tap, timeout } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {

  spinner = inject(NgxSpinnerService);

  public switchLoading(obs$: Observable<any>): Observable<any> {
    return of(null).pipe(
      tap(() => this.spinner.show()),
      switchMap(() => obs$),
      finalize(() => this.spinner.hide())
    );
  }
}
