<div class="max-w-6xl mx-auto p-4">
  <div  class="text-center pt-8">
    <div class="text-3xl ">Buono da {{faceValue | currency: 'EUR'}}</div>
    <div  class="text-2xl py-4">Confermare il numero di telefono del cliente</div>
    <div class="text-3xl"><strong>{{this.number}}</strong></div>
    <div class="mt-12">
      <app-button type="button" (click)="printReceipt()">Stampa prescontrino</app-button>
    </div>
    <div class="mt-12">
      <app-button type="button" (click)="handleUserConfirm()">Conferma e Paga</app-button>
    </div>
  </div>
</div>
