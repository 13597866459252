import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IChallengeTokenRes } from 'src/app/models/response/IChallengeTokenRes';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {


  constructor(private http: HttpClient, private loading: LoadingService) { }

  generateCt(identifier: string, context:string , useMock = false): Observable<IChallengeTokenRes> {
    if(useMock) {
    }
    return this.loading.switchLoading(this.http.post(environment.api.ct, {identifier: identifier ? identifier : '', context: context ? context : ''}).pipe(map((resp: IChallengeTokenRes | any) => {
        if(resp  ) {
          return resp;
        }
        return null;
      }))
    );
  }

}
