import { Issuer } from './../../../models/interfaces/Issuer';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IssuersRes } from '../../../models/response/IIssuersRes';
import { environment } from '../../../../environments/environment';
import { map, timeout } from 'rxjs/operators';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LoadingService } from 'src/app/services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class IssuerService {
  iconsRelations = [
    {
      issuer: 'GlobaMart',
      icon: 'bag-shopping',
    },
    {
      issuer: 'Quantum Stream',
      icon: 'film',
    },
    {
      issuer: 'ReadScape',
      icon: 'book',
    },
    {
      issuer: 'Groove&Green',
      icon: 'shirt',
    },
  ];

  constructor(private http: HttpClient, private loading: LoadingService) {}

  issuers(useMock = false): Observable<Issuer[]> {
    if (useMock) {
    }
    return this.loading.switchLoading(
      this.http.get(environment.api.issuer).pipe(
        map((resp: IssuersRes | any) => {
          if (resp && resp.issuers) {
            return resp.issuers.map((issuer: Issuer) => ({
              ...issuer,
              icon: this.iconsRelations.find(
                (icon) => icon.issuer === issuer.name
              )?.icon as IconProp,
            }));
          }
          return null;
        })
      )
    );
  }
}
