import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { IssuerComponent } from './components/issuer/issuer.component';
import { VoucherTypeComponent } from './components/voucher-type/voucher-type.component';
import { CustomerTypeComponent } from './components/customer-type/customer-type.component';
import { UnregisteredUserComponent } from './components/unregistered-user/unregistered-user.component';
import { ConfirmUserComponent } from './components/confirm-user/confirm-user.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { PaymentResultComponent } from './components/payment-result/payment-result.component';
import { ReaderComponent } from './components/reader/reader.component';
import { SigninComponent } from './components/signin/signin.component';
import { ChallangeTokenComponent } from './components/challange-token/challange-token.component';
import { AdminComponent } from './components/admin/admin.component';

const routes: Routes = [
  {
    path: '',
    children:[
      {
        path: '',
        redirectTo: 'signin',
        pathMatch: 'full',
      },
      {
        path: 'signin',
        component: SigninComponent
      },
      {
        path: 'ct/:challengeToken',
        component: ChallangeTokenComponent
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'issuer',
        component: IssuerComponent
      },
      {
        path: 'voucher-type',
        component: VoucherTypeComponent
      },
      {
        path: 'customer-type',
        component: CustomerTypeComponent
      },
      {
        path: 'qr-reader',
        component: ReaderComponent
      },
      {
        path: 'unregistered-user',
        component: UnregisteredUserComponent
      },
      {
        path: 'confirm-user',
        component: ConfirmUserComponent
      },
      {
        path: 'payment-method',
        component: PaymentMethodComponent
      },
      {
        path: 'payment-result',
        component: PaymentResultComponent
      },
      {
        path: 'admin',
        component: AdminComponent
      }
    ]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
