import {Component, EventEmitter, Output} from '@angular/core';
import {IssuerService} from "./services/issuer.service";
import { BaseComponent } from 'src/app/_shared/base/base.component';
import { Router } from '@angular/router';
import { Issuer } from 'src/app/models/interfaces/Issuer';

@Component({
  selector: 'app-issuer',
  templateUrl: './issuer.component.html',
  styleUrls: ['./issuer.component.scss']
})
export class IssuerComponent extends BaseComponent {


  issuersList$ = this.issuerService.issuers();

  constructor(
    private issuerService: IssuerService,
    router: Router
    ) {
    super(router)
  }

  identify(_index:number, item: Issuer) {
    return item.clientKey;
}

  handleMerchantSelection(clientKey:string){
    //TODO: create service related to
    this.navigateTo('voucher-type', {clientKey});
  }

}
