import { ConfirmUserService } from './services/confirm-user.service';
import {Component, DestroyRef, EventEmitter, Output, inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/_shared/base/base.component';
import { UnregisteredUserService } from '../unregistered-user/services/unregistered-user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IAccountRes } from 'src/app/models/response/IAccountRes';
import { environment } from 'src/environments/environment';
import { STORAGE } from 'src/app/models/enum/general';
import { Settings } from 'src/app/models/interfaces/Settings';
import { StorageService } from 'src/app/services/storage.service';
import * as _ from 'lodash';
import { preReceipt, Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-confirm-user',
  templateUrl: './confirm-user.component.html',
  styleUrls: ['./confirm-user.component.scss']
})
export class ConfirmUserComponent extends BaseComponent{

   voucherKey: string = '';
   number: string = ''; 
   faceValue: string = '';
   destroyRef = inject(DestroyRef);
   scan : boolean = false;
   setting: Settings = {};
   storage = STORAGE;
   receiptToPrint = _.cloneDeep(preReceipt);
   
  constructor(
    router: Router,
    private route: ActivatedRoute,
    private unregisteredUserService: UnregisteredUserService,
    private localStorageService: StorageService,
    private confirmUserService: ConfirmUserService
    ){
      super(router);
    }

    handleUserConfirm(){
     this.account();
    }

    ngOnInit() {
      this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
      this.route.queryParams
        .subscribe(params => {
          this.voucherKey = params['voucherKey'];
          this.number = params['number'];
          this.faceValue = params['faceValue'];
          this.scan = params['scan'] == 'true' ? true : false;
          this.number ? this.setting.extendedVoucherData!.telephoneNumberOwner = this.number : this.setting.extendedVoucherData = {...this.setting.extendedVoucherData, telephoneNumberOwner: ''};
          this.localStorageService.setStorage(this.storage.SETTINGS, this.setting);
        }
      );
    }

    account = () => {
      this.unregisteredUserService.accounts( this.number).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
        {
          next: this.handleissuerRes.bind(this),
          error: this.handleIssuerError.bind(this)
        }
      )
    }
  
    handleissuerRes = (res: IAccountRes) => {
      console.log(`${environment?.webappUrl ? environment?.webappUrl+'/ct/' : ''}${res.ct}`)
      if(environment?.webappUrl && res.ct){
        this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
        this.setting.urlWebApp = `${environment?.webappUrl ? environment?.webappUrl+'/ct/' : ''}${res.ct}`;
        this.localStorageService.setStorage(this.storage.SETTINGS, this.setting);
      }
      this.navigateTo('payment-method', {voucherKey: this.voucherKey, clientkey: res.clientKey, faceValue: this.faceValue});
    }
  
    handleIssuerError = (error: any) => {
      console.log(JSON.stringify(error));
    }

    printReceipt = () => {
    this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
    console.log('setting: ' + JSON.stringify(this.setting.extendedVoucherData));
    const currentDate = new Date(); // Get the current date and time
    const date = Utils.formatDate(currentDate); // Generate the formatted date string
    const amount =  Utils.formatAmount(this.setting.extendedVoucherData?.amount ? this.setting.extendedVoucherData?.amount.toString() : '0,00');
    this.receiptToPrint.data[12].value = Utils.interpolationTemplate(this.receiptToPrint.data[12].value, {date: date});
    this.receiptToPrint.data[14].value = Utils.interpolationTemplate(this.receiptToPrint.data[14].value, {telephoneNumber: this.setting.extendedVoucherData?.telephoneNumberOwner ? this.setting.extendedVoucherData?.telephoneNumberOwner : ''});
    this.receiptToPrint.data[23].value = Utils.interpolationTemplate(this.receiptToPrint.data[23].value, {amount: amount});
    this.receiptToPrint.data[19].value = Utils.interpolationTemplate(this.receiptToPrint.data[19].value, {description: this.setting.extendedVoucherData?.description ? this.setting.extendedVoucherData?.description : ''});
    this.confirmUserService.print(this.receiptToPrint).pipe(takeUntilDestroyed(this.destroyRef)).subscribe( {
      next: this.handleissuerRes.bind(this),
      error: this.handleIssuerError.bind(this)
    });
    }

}
