import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/_shared/base/base.component';
import { VoucherTypeService } from './services/voucher-type.service';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { IIssuerRes } from 'src/app/models/response/IIssuerRes';
import { STORAGE } from 'src/app/models/enum/general';
import { StorageService } from 'src/app/services/storage.service';
import { Settings } from 'src/app/models/interfaces/Settings';


@Component({
  selector: 'app-voucher-type',
  templateUrl: './voucher-type.component.html',
  styleUrls: ['./voucher-type.component.scss']
})
export class VoucherTypeComponent extends BaseComponent {

  voucherTypes$ : Observable<IIssuerRes | any> | undefined
  clientKey: string = '';
  noVoucher: boolean = false;
  setting: Settings = {};
  storage = STORAGE;

  constructor(
    router: Router,
    private voucherTypeService: VoucherTypeService,
    private localStorageService: StorageService,
    private route: ActivatedRoute
  ) {
    super(router);
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        console.log(params); 
        this.clientKey = params['clientKey'];
        this.voucherTypes$ = this.voucherTypeService.issuers(params['clientKey'], false).pipe(map((resp: IIssuerRes[] | any) => {
            if(!resp || !resp.data || resp.data.length < 1 ) {
              this.noVoucher = true;
                return null; 
            } 
            this.noVoucher = false;
            return resp;
          }))
      }
    );
  }

  getGridClasses(count: number): string {
    switch (count) {
      case 1:
        return 'col-12 text-center';
      case 2:
        return 'col-sm-6 text-center';
      case 3:
        return 'col-sm-4 text-center';
      // Add more cases for different counts if needed
      default:
        return 'col-sm-3 text-center';
    }
  }
  
  faceValue: number | null = null

  handleVoucherTypeSelection(type: any) {
    //TODO: create service related to
    this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
    this.setting.extendedVoucherData = {
      amount: type.faceValue ? type.faceValue : '',
      currency: '€',
      description: type.description ? type.description : '',
    }
    this.localStorageService.setStorage(this.storage.SETTINGS, this.setting);
    this.navigateTo('customer-type', type)
  }

  handleFaceValueSelection() {
    this.navigateTo('customer-type', { faceValue: this.faceValue, clientKey: this.clientKey })
  }

  setFaceValue($event: any) {

    this.faceValue = $event
  }





}
