
<div class="min-h-screen min-w-screen bg-gray-100 flex flex-col justify-center">
  <ng-toast ></ng-toast>
  <ngx-spinner
  [fullScreen]="true"
  bdColor="rgba(51,51,51,0.8)"
  color="#fff"
  size="medium"
  type="ball-scale-multiple"
  class="z-50"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
  <app-header></app-header>
  <div class="flex-1 flex flex-col justify-center w-full">
    <router-outlet></router-outlet>
  </div>
</div>
