import {Component, DestroyRef, inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/_shared/base/base.component';
import { PaymentResultService } from '../payment-result/services/payment-result.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TransferRes } from 'src/app/models/response/TransferRes';
import { Settings } from 'src/app/models/interfaces/Settings';
import { STORAGE } from 'src/app/models/enum/general';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent extends BaseComponent{
  clientKey: string = '';
  voucherKey: string = '';
  destroyRef = inject(DestroyRef);
  setting: Settings = {};
  storage = STORAGE;
  
  constructor(
    router: Router,
    public route: ActivatedRoute,
    private paymentResult: PaymentResultService,
    private localStorageService: StorageService,
  ){
    super(router);
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.clientKey = params['clientkey'];
        this.voucherKey = params['voucherKey'];
      }
    );
    this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
  }
  faceValue = this.route.snapshot.queryParams['faceValue'];

  transfer = () => {
    this.paymentResult.transfer( this.clientKey, this.voucherKey).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      {
        next: this.handleissuerRes.bind(this),
        error: this.handleIssuerError.bind(this),
      }
    )
  }
  
  handleissuerRes = (res: TransferRes) => {
    this.setting.extendedVoucherData ? this.setting.extendedVoucherData.txId = res?.txId : '';
    this.localStorageService.setStorage(this.storage.SETTINGS, this.setting);
    this.navigateTo('payment-result', this.route.snapshot.queryParams);
  }

  handleIssuerError = (error: any) => {
    console.log(JSON.stringify(error));
  }

  

  handlePaymentMethodSelection(){
    this.transfer();
  }
}
