<div class="max-w-6xl mx-auto p-4">
  <div class="text-3xl text-center px-8">Merchant convenzionati</div>
  <div class="grid sm:grid-cols-4 gap-8 p-8">
    <ng-container *ngIf="issuersList$ | async as issuerList">
      <div *ngFor="let issuer of issuerList; trackBy:identify" class="bg-gray-300 rounded-2xl p-4">
        <a class="home-sub-container text-2xl flex-col" (click)="
            handleMerchantSelection(issuer.clientKey!)
          "
          [ngStyle]=" {'cursor': 'pointer'}">
          <fa-icon [icon]="issuer.icon ? issuer.icon : 'wallet'" size="xl"></fa-icon>
          <span class="mt-4">{{issuer.name}}</span>
        </a>
      </div>
    </ng-container>
  </div>
</div>
