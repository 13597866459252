<div class="max-w-6xl mx-auto p-4">
  <div class="text-3xl px-8 text-center">Pagamento</div>
  <div class="text-2xl py-8 text-center">Buono da {{faceValue | currency: 'EUR'}}</div>
  <div class="grid sm:grid-cols-2 gap-8 p-8">
    <div class="bg-gray-300 rounded-2xl p-4">
      <div class="home-sub-container text-2xl flex-col" style="cursor: pointer" (click)="handlePaymentMethodSelection()">
        <fa-icon icon="money-bills" size="xl"></fa-icon>
        <span class="mt-4">Contanti</span>
      </div>
    </div>
    <div class="bg-gray-300 rounded-2xl p-4">
      <div class="home-sub-container text-2xl flex-col" style="cursor: pointer" (click)="handlePaymentMethodSelection()">
        <fa-icon icon="credit-card" size="xl"></fa-icon>
        <span class="mt-4">Carta di credito debito</span>
      </div>
    </div>
  </div>
</div>
