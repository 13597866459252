import { Utils } from './../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { TransferRes } from 'src/app/models/response/TransferRes';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentResultService {
  constructor(private http: HttpClient, private loading: LoadingService) {}

  transfer(
    clientKey: string,
    voucherKey: string,
    useMock = false
  ): Observable<TransferRes> {
    if (useMock) {
    }
    return this.loading.switchLoading(
      this.http
        .put(
          Utils.interpolationTemplate(environment.api.transfer, {
            voucherKey: voucherKey,
          }),
          { clientKey: clientKey, assetQuantity: 1 }
        )
        .pipe(
          map((resp: TransferRes | any) => {
            if (resp) {
              return resp;
            }
            return null;
          })
        )
    );
  }
}
