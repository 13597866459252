import { Component, signal, effect } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { BaseComponent } from 'src/app/_shared/base/base.component';

@Component({
  selector: 'app-reader',
  templateUrl: './reader.component.html',
  styleUrls: ['./reader.component.scss']
})
export class ReaderComponent extends BaseComponent {

  constructor(
    router: Router,
    private route: ActivatedRoute
  ) {
    super(router);
    effect(() => {
      console.log('cameraEnabled ' + this.cameraEnabled());
    })
  }

  //ui controls
  cameraEnabled = signal(false)
  showCamera = signal(true)
  orderReady = signal(false)
  params = this.route.snapshot.queryParams

  //component data
  userId: string = ''
  voucherKey: string = '';
  faceValue: string = '';
  scan: boolean = false;

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.voucherKey = params['voucherKey'];
        this.faceValue = params['faceValue'];
        
      }
    );
  }
  
  camerasFoundHandler = (event: any) => {
    this.cameraEnabled.set(true)

  }

  camerasNotFoundHandler = (event: any) => {
    this.showCamera.set(false)

  }

  scanSuccessHandler = (event: any) => {
   this.userId = event;
   this.scan = true;
   console.log(event);
   this.handleProceed()
  }
  scanErrorHandler = (event: any) => {
    //console.log('scanErrorHandler ' + JSON.stringify(event));
  }
  scanFailureHandler = (event: any) => {
    //console.log('scanFailureHandler ' + JSON.stringify(event));
  }
  scanCompleteHandler = (event: any) => {

    //console.log('scanCompleteHandler ' + JSON.stringify(event));
  }

  toggleManualInsertion() {
    this.showCamera.update(value => !value)
  }

  handleProceed() {
    this.orderReady.set(true)
    this.handleConfirm();
  }

  handleConfirm(){
    this.showCamera.set(false);
    this.navigateTo('confirm-user', { number: this.userId, voucherKey: this.voucherKey,faceValue:this.faceValue, scan: this.scan })
  }

  handleChange(event: any) {
    if( !event.includes('+39')){
      event = '+39' + event
    }
    this.scan = false;
    this.userId = event
    // this.value = event.target.value;
  }


}
