import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Observable, map } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfirmUserService {

  constructor(private http: HttpClient, private loading: LoadingService) { }

  print(receipt : any): Observable<any> {
    return this.loading.switchLoading(this.http.post<any>(environment.api.print, receipt).pipe(map((response: any) => {
      return response;
    })))
}

  /*createVoucher( createVoucherReq: ICreateVoucherReq, useMock = false): Observable<IVoucher> {
    if(useMock) {
      let voucherRes: IVoucher = {
        accountKey: "NXycDQKzlQ2HPyDC20O6j6",
        voucherKey: "C5Y2P9CtuO2.KT8hRihxyfQFehy0KWQN8LfRqdv",
        purpose: "gift-card-code",
        image: "https://voucher.fp8.app/voucher/C5Y2P9CtuO2.KT8hRihxyfQFehy0KWQN8LfRqdv",
        description: "Buono Regalo Amazon.it di €50",
        currency: "eur",
        amount: 5000,
        expiry: "2024-07-31",
        state: "active",
        createdOn: "2023-07-28T10:01:25Z"
      }
      return of<IVoucher>(voucherRes);
    }
    return this.http.get(environment.api.createvoucher).pipe(map((resp: IVoucher | any) => {
        if(resp && resp.data ) {
          return resp;
        }
        return null;
      })
    );
  }*/

}
