<button [disabled]="disabled" [type]="type" class="py-4 px-6 rounded-lg uppercase" [ngClass]="{
    'bg-gray-200 text-white py-5' : (style === 'primary' && disabled),
    'border-gray-200 border-2 text-gray-200' :(style === 'secondary' && disabled),
    'bg-gray-400 text-white py-5' : style === 'primary',
    'border-gray-400 border-2 text-gray-400' : style === 'secondary',
  }">
  <strong>
    <ng-content></ng-content>
  </strong>
</button>
