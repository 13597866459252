import { Component, EventEmitter, Input, OnInit, Output, effect, signal } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit{

  @Input() disabled: boolean = false
  @Input() value: any = ''
  @Input() placeholder: string = ''
  @Input() type: string = 'text'
  @Input() ariaLabel: string = ''
  @Input() cless: string = ''
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>()
  innerValue = signal(this.value)
  constructor() {
    effect(() => {
      this.onChange.emit(this.innerValue())
    })
  }
  ngOnInit(): void {
    this.innerValue.set(this.value)
  }
  setInnerValue(event: any) {
    this.innerValue.set(event.target.value)
  }

}
