<div class="max-w-6xl mx-auto p-4">
  <div *ngIf="!orderReady() || showCamera()" class="text-3xl p-8 text-center">
    Inserisci Id Utente
  </div>

  <!-- <ng-container *ngIf="!orderReady(); else confirmOrder"> -->
    <ng-container *ngIf="showCamera(); else manualInsertion">
      <div class="scanner-container">
        <!-- <fa-icon icon="qrcode" class="text-gray-300 text-8xl absolute"></fa-icon> -->
        <div class="scanner-square" [ngClass]="cameraEnabled() ? 'active' : ''">
          <div class="scanner-bar"></div>
        </div>
        <div class="flex justify-center align-center relative max-w-xl">
          <zxing-scanner
            [enable]="cameraEnabled()"
            [style]="{ width: '100%', height: '350px' }"
            (camerasFound)="camerasFoundHandler($event)"
            (camerasNotFound)="camerasNotFoundHandler($event)"
            (scanSuccess)="scanSuccessHandler($event)"
            (scanError)="scanErrorHandler($event)"
            (scanFailure)="scanFailureHandler($event)"
            (scanComplete)="scanCompleteHandler($event)"
          />
        </div>
      </div>
    </ng-container>
    <ng-template #manualInsertion>
      <div class="flex flex-col justify-center align-center py-12">
        <label for="qr-code" class="text-center text-3xl">
          Scrivere il numero di telefono dell'utente LIS
        </label>

        <div class="mx-auto">
          <fa-icon icon="mobile-screen" size="xl"></fa-icon>
          <app-input
            type="text"
            id="qr-conde"
            placeholder="inserisci codice utente"
            [value]="userId"
            class="mx-auto"
            (onChange)="handleChange($event)"
          />
          <!-- (onChange)="userId = $event" -->
        </div>
      </div>
    </ng-template>
    <div class="flex gap-8 justify-center py-4">
      <app-button [style]="'secondary'" (click)="toggleManualInsertion()">
        {{ !showCamera() ? "Scansiona" : "Inserisci Manualmente" }}
      </app-button>
      <app-button [disabled]="!userId.length" (click)="handleConfirm()">
        Procedi
      </app-button>
    </div>
  <!-- </ng-container> -->
  <!-- <ng-template #confirmOrder>
    <div class="flex flex-col justify-center align-center py-12">
      <div class="text-center">
        <h1 class="text-4xl">
          Buono da {{ params["faceValue"] | currency : "EUR" }}
        </h1>
        <div class="text-2xl py-4">Confermare il numero di telefono del cliente</div>
        <div class="text-3xl"><strong>{{this.userId}}</strong></div>
      </div>

    
      <div class="mt-8 mx-auto">
        <app-button (click)="">Conferma Ordine</app-button>
      </div>
    </div>
  </ng-template> -->
</div>
