
import { Utils } from './../../../utils/utils';
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";
import {IIssuerRes} from "../../../models/response/IIssuerRes";
import {Voucher} from "../../../models/interfaces/Voucher";


@Injectable({
  providedIn: 'root'
})
export class VoucherTypeService {

  constructor(private http: HttpClient) { }

  issuers( issuerClientKey: string, useMock = false): Observable<IIssuerRes | any> {
    if(useMock) {
      let issuer1: Voucher = {
        "description": "Amazon",
        "purpose": "gift-card-code",
        "currency": "eur",
        "faceValue": 50,
        "image": "assets/issuer/amazon_it/gift_card.png"
      };
      let issuer2: Voucher = {
        "description": "Amazon",
        "purpose": "gift-card-code",
        "currency": "eur",
        "faceValue": 100,
        "image": "assets/issuer/amazon_it/gift_card.png"
      }
      let issuer3: Voucher = {
        "description": "Amazon",
        "purpose": "gift-card-code",
        "currency": "eur",
        "faceValue": 200,
        "image": "assets/issuer/amazon_it/gift_card.png"
      }
      let array: [Voucher] = [issuer1];
      array.push(issuer2, issuer3);
      let res: IIssuerRes = {
        data: array,
        allowCustomFaceValue: true
      }

      return of<IIssuerRes>(res) ;
    }
    return this.http.get(Utils.interpolationTemplate(environment.api.vouchers, {issuerClientKey: issuerClientKey})).pipe(map((resp: Voucher[] | any) => {
        if(resp) {
          let res: IIssuerRes = {
            data: resp.map((el: Voucher) => {
              return {
                ...el,
                nominalValue: Math.trunc(el.nominalValue!/100)
              }// Return the modified object after updating faceValue
            }),
            allowCustomFaceValue: false
          }
          return res;
        }
        return null;
      })
    );
  }
}
