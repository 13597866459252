
<section class="flex flex-col justify-between">

      <div class="flex items-center flex-col">
        <p class="text-gray-500 dark:text-gray-900 text-4xl text-center pt-2"  >Pannello Admin</p>
        <p class="text-gray-500 dark:text-gray-900 text-2xl text-center pt-6">Scegli tipologia utente</p>
        <select [(ngModel)]="context"  class="form-select form-select-lg mb-3 mt-6 p-3" aria-label="form-select-lg example" style="width: 40%; border-radius:10px">
            <option selected value="mobile-account">Client</option>
            <option value="seller-account">Seller</option>
            <option value="merchant-account">Merchant</option>
        </select>


        <p class="text-gray-500 dark:text-gray-400 text-2xl text-center pt-3">Inserisci identificativo</p>
      <div class="flex flex-col justify-between items-center gap-2 w-full pt-10">
        <div class="relative w-full pl-10 pr-10">        
          <input [(ngModel)]="identify" [ngModelOptions]="{standalone: true}" type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Inserire identificativo" required>
       </div>
      </div>
      <div class="flex items-center flex-col w-full pl-10 pr-10 pt-10">
      <button class="rounded-full bg-gray-200  w-full py-4 px-6 m-4" [disabled]="!identify || identify.length < 1" (click)="generateCt()">
        <span class="uppercase text-xl">
          Genera Challenge Token
        </span>
      </button>
      <div style="font-size: 20px;"><a href="{{webappUrl}}" target="_blank">{{webappUrl}}</a></div>
    </div>
    </div>
 
    
  </section>
  
