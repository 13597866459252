import {Component, EventEmitter, Output} from '@angular/core';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

import {UnregisteredUserService} from "./services/unregistered-user.service";
import {IAccountRes} from "../../models/response/IAccountRes";
import { BaseComponent } from 'src/app/_shared/base/base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-unregistered-user',
  templateUrl: './unregistered-user.component.html',
  styleUrls: ['./unregistered-user.component.scss']
})
export class UnregisteredUserComponent extends BaseComponent {
  @Output() setPage = new EventEmitter<number>();
  value: string = '';
  voucherKey: string = '';
  faceValue: string = '';


  constructor(
    router: Router,
    private route: ActivatedRoute
    ) {
    super(router)
   
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.voucherKey = params['voucherKey'];
        this.faceValue = params['faceValue'];
        // this.value = '+393315689524';
      }
    );
  }

  routeParams = this.route.snapshot.queryParams;


  hadleUserRegistration(){
    this.navigateTo('confirm-user', {voucherKey: this.voucherKey, number: this.value, faceValue: this.faceValue});
  }
  
  handleChange(event: any) {
    if( !event.includes('+39')){
      event = '+39' + event
    }
  
    this.value = event
    // this.value = event.target.value;
  }

  
}
